
import Routes from "@/Routes";
import { Component, Vue } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import InviteStore from "@/store/InviteStore";
import CreateInviteByCodeResponse from "@/models/CreateInviteByCodeResponse";
import CreateInviteByCodeRequest from "@/models/CreateInviteByCodeRequest";
import AppStore from "@/store/AppStore";
import { EmailWithoutPasswordRegisterRequest, EmailWithPasswordRegisterRequest } from "@/models/RegisterRequest";
import AuthStore from "@/store/AuthStore";
import Organization from "@/models/Organization";
import OrganizationStore from "@/store/OrganizationStore";
import { AxiosError } from "axios";
import Vuetify from "vuetify/lib";
import IAppTheme from "@/../../Main/src/themes/IAppTheme";

@Component({
  components: {
  },
})
export default class Signup extends Vue {
  loading = false;
  validatingCode = false;
  invitationCode = "";
  invitationCodeValid = false;
  signupWithEmailSelected = false;
  signupEmail = "";
  inviteCreated = false;
  inviteEmailSentSuccessfully = false;

  firstName = "";
  lastName = "";
  identityVerificationFragment = "";
  emailIsTaken = false;

  passwordVisible = false;
  confirmPasswordVisible = false;
  password = "";
  confirmPassword = "";

  get appTheme(): IAppTheme {
    return AppStore.appTheme
  }

  get appThemeDark(): boolean {
    return this.appTheme.dark;
  }

  get appThemes(): IAppTheme[] {
    return AppStore.appThemes
  }
  
  get appName() {
    return this.appTheme?.appName ? this.appTheme?.appName : process.env.VUE_APP_NAME
  }

  get isAdminSigninPage() {
    return this.$route.query.role && this.$route.query.role === "admin";
    // return process.env.NODE_ENV === "development"
    //   ? true
    //   : process.env.NODE_ENV === "production" && 
  }

  get signUpFormIsValid(): boolean {
    console.log("getter running ...")
    const signUpFormRef =  this.$refs.signUpForm as any;
    if (!signUpFormRef){
      return false;
    }
    if (this.signupEmail && !this.emailIsTaken){
      console.log("validating");
      signUpFormRef.validate();
    }
    const isValid = signUpFormRef.valid;
    console.log("isValid:", isValid)
    return isValid;
  }

  get rules(): { [key: string]: any; } {
    return {
      required: (v: any) => 
        !!v || 
        this.$t('generic.validation.required'),
      email: (v: any) => 
        /.+@.+/.test(v) || 
        this.$t('generic.validation.validEmail'),
      emailIsAvailable: (v: any) => 
        !this.emailIsTaken || 
        this.$t('generic.validation.emailIsAvailable'),
        passwordMinLength: (v: any) =>
        v.length >= 10 || 
        this.$t('generic.validation.passwordMinLength', { number: 10 }),
      passwordContainsUppercase: (v: any) => 
        /[A-Z]/.test(v) ||
        this.$t('generic.validation.passwordContainsUppercase'),
      passwordContainsLowercase: (v: any) => 
        /[a-z]/.test(v) ||
        this.$t('generic.validation.passwordContainsLowercase'),
      passwordContainsNumber: (v: any) => 
        /[0-9]/.test(v) || 
        this.$t('generic.validation.passwordContainsNumber'),
      confirmPassword: (v: any) => 
        this.password === this.confirmPassword ||
        this.$t('generic.validation.confirmPassword')
    }
  }

  get signinRoute(): RouteConfig {
    return Routes.signin;
  }

  get useInvitationCodes(): boolean {
    return AppStore.features?.invitationCodes
      ? true 
      : false;
  }

  selectSignupWithEmailMethod() {
    this.signupWithEmailSelected = true;
  }

  clearServerErrors(){
    this.emailIsTaken = false;
  }

  async onSignupWithEmailClick() {
    (this.$refs.signUpForm as any).validate();
    if (!this.signupEmail || (this.useInvitationCodes && !this.invitationCode)) {
      return;
    }
    this.clearServerErrors();
    this.loading = true;
    if (this.useInvitationCodes){
      const request =  new CreateInviteByCodeRequest(this.signupEmail, this.invitationCode);
      const response = await InviteStore.createInviteByCode(request);
      if (response instanceof CreateInviteByCodeResponse) {
        this.inviteCreated = true;
        this.inviteEmailSentSuccessfully = response.emailSent;
      }
    } else {
      let request = null;
      if (this.usePasswordlessSignup){
        request = new EmailWithoutPasswordRegisterRequest();
      } else {
        request = new EmailWithPasswordRegisterRequest();
       
        request.password = this.password;
        request.confirmPassword = this.confirmPassword;
      }
      request.email = this.signupEmail;
      request.firstName = this.firstName;
      request.lastName = this.lastName;
        
        request.identityVerificationFragment = this.identityVerificationFragment;
        if (this.useOrganizationAccessCodes && this.organization && this.organization.accessCode){
          request.organizationAccessCode = this.organization.accessCode;
        }
        let response = null;
        try {
          response = this.usePasswordlessSignup
            ? await AuthStore.registerWithEmailWithoutPassword(request as EmailWithoutPasswordRegisterRequest)
            : await AuthStore.registerWithEmailWithPassword(request as EmailWithPasswordRegisterRequest);
        } catch(e){
          if ((e as any).isAxiosError){
            if ((e as AxiosError).response?.data?.message?.includes("Email is already taken")){
              this.emailIsTaken = true;
            }
          }
        }
      
      // No need to handle response separately, error will be notifified or and success will cause a page load
    }
    (this.$refs.signUpForm as any).validate();
    this.loading = false;
  }

  async onSigninWithMsalClick() {
    this.$toast.error('This feature has not been enable in this version.')
    // this.msalLoginInProgress = true;

    // try {
    //   await this.$msal.signIn();
    //   this.msalLoginInProgress = false;
    // } catch (e) {
    //   const message = "There was a problem signing you in with an external provider. Try again later or contact support.";
    //   NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, message));
    //   this.msalLoginInProgress = false;
    // }
  }

  async onSigninWithGoogleClick() {
    this.$toast.error('This feature has not been enable in this version.')
    // this.googleLoginInProgress = true;
    // try {
    //   await this.$firebaseAuth.signInWithGoogle();
    // } catch (e) {
    //   const message = "There was a problem signing you in with an external provider. Try again later or contact support.";
    //   NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, message));
    // }

    // this.googleLoginInProgress = false;
  }

  async validateInvitationCode() {
    if (!(this.$refs.invitationCodeForm as any).validate()) {
      return;
    }
    this.validatingCode = true;
    const codeIsValid = await InviteStore.validateCode(this.invitationCode);
    if (codeIsValid) {
      this.invitationCodeValid = true;
    }
    this.validatingCode = false;
  }

  get useOrganizationAccessCodes(): boolean {
    return AppStore.features?.organizationAccessCodes?.enabled
      ? true 
      : false;
  }

  get organization(): Organization | null {
    return OrganizationStore.organization;
  }

  get organizationIsVisitor(): boolean {
    return this.organization
      ? this.organization.visitor
      : false;
  }

  get usePasswordlessSignup(): boolean {
     return this.organizationIsVisitor;
  }

  async mounted(){
    if (!this.isAdminSigninPage){
      this.signupWithEmailSelected = true;
    }
  }

  async created() {
    (window as any).__signup = this;
    if (this.useInvitationCodes){
      this.invitationCode = this.$route.query.c ? this.$route.query.c.toString() : "";
      if (this.invitationCode) {
        await this.validateInvitationCode();
      }
    }
    if (this.useOrganizationAccessCodes){
      if (!this.organization || !this.organization.accessCode){
        this.$router.push(Routes.access);
      }
    }
  }
}
