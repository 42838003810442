class RegisterRequestBase {
  firstName: string;
  lastName: string;
  identityVerificationFragment: string;
  organizationAccessCode: string;
}

export class PhoneNumberWithoutPasswordRegisterRequest extends RegisterRequestBase {
  phoneNumber: string;
}

export class EmailWithoutPasswordRegisterRequest extends RegisterRequestBase {
  email: string;
}

export class EmailWithPasswordRegisterRequest extends EmailWithoutPasswordRegisterRequest {
  password: string;
  confirmPassword: string;
}

export default class InviteRegisterRequest extends RegisterRequestBase {
  inviteToken: string;
}
